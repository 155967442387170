.image {
  user-select: none; }
  .image .editor-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    height: 40px;
    font-size: 11px;
    padding-left: 5px; }
    .image .editor-header li.back {
      width: 270px; }
    .image .editor-header a.back {
      color: #C6CBCD;
      display: inline-block;
      font-size: 16px; }
      .image .editor-header a.back i {
        margin-right: 5px; }
    .image .editor-header li i.fa-eye-slash {
      color: #b4b4b4; }
    .image .editor-header .current-zoom {
      color: white;
      position: absolute;
      right: 0; }
    .image .editor-header .slider-container {
      width: 230px;
      margin-top: 8px; }
      .image .editor-header .slider-container .slider {
        right: 70px;
        padding-top: 2px; }
      .image .editor-header .slider-container .label-right {
        right: 50px;
        top: 5px; }
      .image .editor-header .slider-container .label-left {
        top: 5px; }
  .image .virtual-image {
    position: absolute;
    top: 0;
    left: 0; }
  .image .close {
    font-size: 12px;
    float: right;
    line-height: 1;
    text-shadow: none;
    margin-top: 2px;
    margin-right: 4px; }
  .image .scroll-container {
    position: absolute;
    left: 270px;
    right: 60px;
    top: 40px;
    bottom: 0;
    overflow: auto;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.5); }
    .image .scroll-container.fullscreen {
      top: 0;
      left: 0;
      right: 0; }
  .image .panel.left {
    position: absolute;
    left: 0;
    width: 270px;
    top: 40px;
    bottom: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background-color: #444444;
    color: #C6CBCD;
    margin: 0;
    word-wrap: break-word;
    user-select: none;
    display: flex;
    flex-direction: column; }
    .image .panel.left .image-details-more {
      color: #898c8e;
      cursor: pointer; }
    .image .panel.left .image-details-more-content {
      margin-top: 20px; }
    .image .panel.left .label {
      padding: 0;
      font-size: 100%;
      font-weight: normal; }
    .image .panel.left .tag {
      color: #C4C4C4;
      background-color: #3C3C3C;
      font-size: 12px; }
    .image .panel.left .download {
      float: right; }
    .image .panel.left a {
      color: #C6CBCD; }
      .image .panel.left a.editable-empty, .image .panel.left a.editable-empty:hover, .image .panel.left a.editable-empty:focus {
        color: #999d9e; }
    .image .panel.left .empty {
      opacity: 0.5; }
    .image .panel.left .color-tags-dropdown > a:first-child .img-color-tag.color-none {
      border: 1px solid #C6CBCD; }
      .image .panel.left .color-tags-dropdown > a:first-child .img-color-tag.color-none:before, .image .panel.left .color-tags-dropdown > a:first-child .img-color-tag.color-none:after {
        background-color: #C6CBCD; }
    .image .panel.left .tag-container {
      margin-top: 20px;
      margin-bottom: 10px; }
    .image .panel.left .toolbar-tabs {
      background-color: #333333;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      flex-shrink: 0; }
      .image .panel.left .toolbar-tabs.tabbar-details {
        border-top: 1px solid #333333; }
      .image .panel.left .toolbar-tabs > li {
        list-style: none;
        display: inline-block;
        flex-grow: 1;
        text-align: center; }
        .image .panel.left .toolbar-tabs > li > a {
          padding: 5px;
          font-size: 18px;
          width: 100%;
          display: inline-block;
          color: #898c8e; }
          .image .panel.left .toolbar-tabs > li > a:hover {
            text-decoration: none;
            color: white; }
          .image .panel.left .toolbar-tabs > li > a.active {
            text-decoration: none;
            background-color: #3C3C3C;
            color: white; }
      .image .panel.left .toolbar-tabs.box-info > li > a {
        font-size: 12px; }
  .image .tools-nav {
    position: relative;
    height: 40px;
    background-color: #3c3c3c;
    text-align: center;
    white-space: nowrap;
    flex-shrink: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #3c3c3c; }
    .image .tools-nav a {
      display: inline-block;
      width: 50%;
      height: 100%;
      color: #C6CBCD;
      text-align: center;
      cursor: pointer;
      padding-top: 12px; }
      .image .tools-nav a:hover {
        background-color: #444444; }
      .image .tools-nav a i {
        font-size: 11px;
        margin: 0 5px; }
    .image .tools-nav .image-count {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      pointer-events: none;
      padding-top: 12px; }
      .image .tools-nav .image-count span {
        background-color: #3c3c3c;
        padding: 2px 5px;
        border-radius: 3px; }
  .image .image-info {
    padding: 0 20px 10px 20px;
    flex: 0 1 auto; }
    .image .image-info a.image-description {
      font-size: 14px;
      white-space: pre-wrap; }
    .image .image-info h1.image-name {
      font-size: 22px;
      margin-top: 0;
      margin-bottom: 12px;
      color: #fff;
      line-height: 1.5; }
      .image .image-info h1.image-name a {
        color: #fff; }
  .image .form-inline {
    width: 100%; }
  .image .editable-input {
    width: inherit; }
  .image elements-list.locked .asset-delete, .image elements-list.locked .handle {
    display: none; }
  .image elements-list .assets {
    position: relative;
    clear: both;
    list-style: none;
    margin: 0;
    padding: 5px 0;
    min-height: 50px; }
    .image elements-list .assets li dnd-nodrag {
      padding: 8px 20px;
      cursor: pointer;
      display: flex;
      justify-content: space-between; }
    .image elements-list .assets li.active {
      color: #fff; }
    .image elements-list .assets li:hover:not(.empty) {
      background-color: #444444; }
    .image elements-list .assets li .asset-index {
      width: 12px;
      display: inline-block;
      flex: 0 0 12px;
      margin-right: 8px;
      white-space: nowrap; }
    .image elements-list .assets li .asset-label {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .image elements-list .assets li .asset-label.default {
        opacity: 0.5; }
      .image elements-list .assets li .asset-label .note-user {
        font-weight: bold; }
    .image elements-list .assets li .asset-delete {
      float: right;
      margin-top: 2px;
      margin-left: 6px;
      width: 12px;
      flex-grow: 0;
      flex-basis: 12px;
      flex-shrink: 0; }
    .image elements-list .assets[dnd-list] .dndDraggingSource {
      display: none; }
    .image elements-list .assets[dnd-list] .dndPlaceholder {
      background-color: #333333;
      display: block;
      min-height: 34px; }
    .image elements-list .assets .handle {
      margin-right: 10px;
      opacity: 0.5;
      cursor: move; }
  .image form.values {
    position: relative;
    padding: 10px 10px 0 10px;
    white-space: nowrap; }
    .image form.values.form-horizontal .form-group {
      margin-bottom: 10px; }
      .image form.values.form-horizontal .form-group .control-label {
        padding-top: 2px;
        margin-bottom: 0;
        text-align: left; }
    .image form.values label {
      font-weight: normal;
      color: rgba(255, 255, 255, 0.4); }
    .image form.values input[type="number"] {
      text-align: left; }
    .image form.values .rect-comments {
      white-space: normal; }
  .image .form-control {
    color: #C6CBCD;
    background-color: transparent;
    border: 1px solid #686868;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    height: 22px;
    padding: 2px 4px;
    font-size: inherit; }
    .image .form-control:focus {
      border-color: #C6CBCD; }
    .image .form-control.additional-element {
      margin-top: 10px;
      height: 88px; }
  .image textarea.form-control {
    height: 40px; }
  .image .form-control-static {
    padding-top: 2px;
    padding-bottom: 0;
    margin-bottom: 0;
    min-height: initial;
    overflow: hidden;
    text-overflow: ellipsis; }
  .image .tools {
    position: absolute;
    right: 0;
    width: 60px;
    top: 40px;
    bottom: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background-color: #4c4c4c;
    color: #C6CBCD;
    padding: 10px;
    margin: 0;
    word-wrap: break-word; }
    .image .tools.fullscreen {
      top: 0;
      z-index: 1; }
    .image .tools button {
      width: 100%;
      margin-bottom: 2px;
      margin-top: 2px;
      padding: 6px 6px;
      height: 34px; }
    .image .tools .tools-label {
      position: relative;
      margin-bottom: 10px;
      color: #909AA0;
      overflow: hidden; }
      .image .tools .tools-label:after {
        position: absolute;
        top: 10px;
        overflow: hidden;
        width: 100%;
        height: 1px;
        content: '\a0';
        background-color: #686868;
        margin-left: 5px; }
  .image .toggle-lock {
    display: block; }
    .image .toggle-lock.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 2px; }
  .image .btn-tools {
    color: #C6CBCD;
    background-color: transparent; }
    .image .btn-tools span {
      font-size: 18px; }
    .image .btn-tools.locking {
      border: none;
      margin: 0;
      height: auto; }
      .image .btn-tools.locking.locked {
        background-color: #616161; }
        .image .btn-tools.locking.locked.active {
          background-color: #e0433a;
          color: #f4f4f4;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.15); }
      .image .btn-tools.locking.unlocked {
        background-color: #616161; }
        .image .btn-tools.locking.unlocked.active {
          background-color: #32ab44;
          color: #f4f4f4;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.15); }
    .image .btn-tools:hover {
      background-color: #424445; }
    .image .btn-tools.active, .image .btn-tools:active {
      background-color: rgba(0, 0, 0, 0.3);
      color: #959DA1; }
    .image .btn-tools.disabled {
      opacity: 0.4; }
      .image .btn-tools.disabled.active {
        background-color: transparent;
        box-shadow: none;
        color: #c0cbd1; }
        .image .btn-tools.disabled.active:hover {
          background-color: #424445; }
    .image .btn-tools.parallel {
      width: 18px;
      display: inline-block;
      padding: 0; }
  .image .visibility {
    border-radius: 2px;
    background-color: #565656;
    padding: 2px 4px 2px 4px;
    margin: -4px; }
  .image .toolbar-element-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    height: 100%; }
  .image .list-color-area {
    display: inline-block;
    width: 18px;
    height: 17px;
    margin-right: 3px;
    vertical-align: text-top; }

div.fullscreen-controller {
  position: relative;
  top: 50px;
  right: 70px;
  background-color: white;
  padding: 2px;
  border-radius: 3px;
  color: #000000;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-align: center;
  opacity: 0.8; }

.fullscreen-toolbar {
  position: fixed;
  top: 10px;
  right: 10px;
  display: inline-block;
  z-index: 5;
  background-color: #4c4c4c;
  border-radius: 3px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  padding: 3px; }
  .fullscreen-toolbar .img-controls {
    display: inline-block; }

.clickdummy-toolbar {
  position: fixed;
  top: 10px;
  right: 10px;
  display: inline-block;
  z-index: 5;
  background-color: #4c4c4c;
  border-radius: 3px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  padding: 3px; }
  .clickdummy-toolbar .img-controls {
    display: inline-block; }

.bg {
  position: absolute;
  top: 40px;
  left: 270px;
  right: 60px;
  bottom: 0;
  background: url(../img/checkerboard.png); }

.bg.fullscreen {
  top: 0;
  left: 0;
  right: 0; }

.canvas-container {
  position: fixed;
  left: 50%;
  top: 50%; }

#canvas-pixelgrid {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  /* dont listen to mouse events and forward them */ }

#canvas-image {
  position: absolute;
  top: 0;
  left: 0; }

.element-container {
  position: absolute; }

.elements {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5); }

.elements-container.disabled {
  pointer-events: none; }

.shortcut {
  color: #999; }

.zoom-rect {
  position: absolute;
  border: 1px dashed #99c2f8;
  background-color: rgba(77, 141, 191, 0.2);
  pointer-events: none;
  z-index: 10; }

.canvas-guide {
  position: absolute;
  width: 100%;
  height: 10px;
  border-top: 1px solid #ff00ee;
  cursor: ns-resize; }
  .canvas-guide.vertical {
    height: 100%;
    width: 10px;
    border: none;
    border-left: 1px solid #ff00ee;
    cursor: ew-resize; }
  .canvas-guide.locked {
    cursor: auto; }

.canvas-guide-element-info {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 0 4px;
  border-radius: 2px;
  font-size: 11px;
  display: none; }

toolbar-guides {
  flex: 1 1 auto;
  height: 100px; }
  toolbar-guides .toolbar-guides {
    height: 100%;
    display: flex;
    flex-direction: column; }
    toolbar-guides .toolbar-guides .toolbar-guides-content {
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1 1 auto;
      padding-bottom: 10px;
      min-height: 100px; }
      toolbar-guides .toolbar-guides .toolbar-guides-content input {
        width: 137px; }
    toolbar-guides .toolbar-guides i.fa-times {
      padding-left: 2px; }
    toolbar-guides .toolbar-guides .locked i.fa-times {
      display: none; }
  toolbar-guides .toolbar-guides-header {
    padding: 5px 10px;
    background-color: #3c3c3c; }
    toolbar-guides .toolbar-guides-header .btn {
      padding: 0;
      border: none; }
  toolbar-guides .empty {
    padding: 10px; }

elements-list {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 50px;
  background-color: #3C3C3C; }

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid transparent;
    border-radius: 1px 1px 0 0; } }

@media (max-width: 768px) {
  .image .panel.left,
  .image .tools {
    display: none; }
  .image .scroll-container {
    left: 0;
    right: 0; }
  .bg {
    left: 0; } }

.file .image-name {
  font-weight: bold;
  margin-top: 0; }

.file .editable-empty {
  opacity: 0.5; }

.file .tag-container {
  margin: 5px 0; }

.file .file-comments {
  display: flex;
  flex-direction: column; }
  .file .file-comments ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0; }
    .file .file-comments ul li {
      padding: 6px 0;
      margin: 8px 0; }
      .file .file-comments ul li .header {
        margin-bottom: 5px; }
      .file .file-comments ul li .user {
        font-weight: bold; }
      .file .file-comments ul li .created {
        color: #8a8d8e; }
      .file .file-comments ul li .comment {
        white-space: pre-wrap;
        color: #000; }
      .file .file-comments ul li textarea {
        color: #333;
        border: 1px solid #ccc; }
      .file .file-comments ul li .badge {
        vertical-align: inherit;
        border-radius: 2px;
        background-color: #D8D8D8;
        padding: 3px 5px;
        color: #000000;
        margin-right: 5px; }
      .file .file-comments ul li .form-inline {
        width: 100%; }
      .file .file-comments ul li .editable-input {
        width: inherit; }
  .file .file-comments .add-entry-form {
    margin-top: 15px; }
    .file .file-comments .add-entry-form textarea {
      margin: 8px 0; }
    .file .file-comments .add-entry-form .btn-primary {
      color: #C6CBCD;
      background-color: #676767;
      border-color: #676767; }
  .file .file-comments .editable-buttons {
    white-space: normal;
    margin-top: 5px;
    margin-left: -5px;
    display: flex;
    justify-content: space-between;
    width: 40%;
    float: right; }
    .file .file-comments .editable-buttons.single {
      margin-left: 0; }
      .file .file-comments .editable-buttons.single button {
        margin-left: 0; }
    .file .file-comments .editable-buttons button {
      padding: 2px 12px;
      margin-left: 5px;
      flex-grow: 2; }

.file .file-info {
  margin: 30px auto;
  text-align: center; }
  .file .file-info .file-name {
    font-size: 14px; }

.file .file-icon-container {
  display: inline-block;
  width: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  margin-bottom: 20px; }
  .file .file-icon-container > a > i {
    font-size: 64px; }

.file .btn-download {
  border-radius: 20px;
  padding: 1px 8px;
  margin-top: 10px;
  background-color: #f3f3f3; }

.file .file-name {
  margin-bottom: 5px; }

.file .panel-body {
  padding: 40px 40px 20px 40px; }

.file .panel .panel-heading {
  background-color: #FAFAFA;
  border-bottom: 1px solid #ccc;
  padding: 30px 40px; }

.file a.back-to-projects {
  margin-top: -20px;
  display: block; }

header {
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 4; }

.navbar-default {
  background-color: #313131;
  border: none;
  height: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13); }

header .navbar {
  border-radius: 0;
  margin-bottom: 0;
  min-height: 0; }

.navbar-nav > li > a {
  padding-top: 11px;
  height: 40px; }

.navbar-text {
  margin-top: 11px;
  margin-bottom: 10px; }

.navbar-nav .btn-group {
  margin-top: 4px; }

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #E8F0F5;
  background-color: #55595d; }

.navbar-default .navbar-nav > li > a {
  color: #C6CBCD; }

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #fff;
  background-color: transparent; }

.navbar-right {
  margin-right: 0; }

header .project-name {
  font-size: 16px;
  margin-right: 30px; }
  header .project-name i {
    margin-right: 10px; }

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  background-color: #313131;
  border: none;
  box-shadow: none; }

.navbar-default .navbar-toggle {
  color: #ddd;
  border-color: transparent;
  padding: 7px 10px; }

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #5f5f5f; }

.navbar-brand .logo {
  margin-top: -5px;
  margin-right: 30px;
  margin-left: 25px; }
  .navbar-brand .logo svg.discuss-logo rect {
    stroke: #ffdc00 !important; }
  .navbar-brand .logo .logo-text {
    margin-top: -5px;
    margin-left: 5px; }

.navbar-brand.project-name {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  margin: auto;
  pointer-events: none; }

@media (max-width: 992px) {
  .navbar-brand .logo {
    margin-left: 0; } }

.image-list-content {
  position: relative; }
  .image-list-content .filter {
    width: 200px; }
    .image-list-content .filter .form-horizontal .control-label {
      text-align: left; }
  .image-list-content .image-list {
    position: absolute;
    left: 230px;
    right: 0;
    top: 0;
    bottom: 0; }
    .image-list-content .image-list.fullwidth {
      left: 0; }
    .image-list-content .image-list .image-list-toolbar {
      padding: 15px;
      background-color: #ffffff;
      margin-bottom: 10px; }
      .image-list-content .image-list .image-list-toolbar .main-toolbar {
        display: flex;
        justify-content: space-between; }

@media (max-width: 800px) {
  .image-list-content .filter {
    width: auto; }
  .image-list-content .image-list {
    position: relative;
    display: block;
    left: 0; } }

.project-info {
  margin-top: -44px;
  margin-bottom: 25px;
  color: #999; }

.preview {
  width: 300px;
  text-align: center; }
  .preview .preview-image {
    background-color: #eff0f1;
    height: inherit; }
    .preview .preview-image .preview-image-container {
      position: relative;
      display: flex;
      align-items: center;
      align-self: center;
      max-width: 100%;
      max-height: 100%; }
    .preview .preview-image:hover .zoom {
      opacity: 1; }
  .preview .zoom {
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
    padding: 5px 7px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: opacity 0.2s;
    opacity: 0;
    right: 3px;
    top: 3px;
    border-radius: 50%; }
  .preview a.replace-file {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    padding-top: 9px;
    background-color: #438DC7;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    /* opacity: 0.9; */
    border: 3px solid white;
    color: white;
    text-align: center; }
    .preview a.replace-file:hover {
      opacity: 1;
      background-color: #d4d7d9;
      color: #000; }
  .preview img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    vertical-align: baseline;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    align-self: center;
    border: 1px solid #cccccc; }
    .preview img:not([src]) {
      display: none; }

.slider-container {
  display: inline-block;
  width: 220px;
  position: relative;
  height: 12px;
  margin-right: 20px; }
  .slider-container .slider {
    position: absolute;
    left: 20px;
    right: 20px; }
  .slider-container .rzslider {
    margin: 0; }
  .slider-container .rzslider .rz-bar {
    background: #e1e4e5; }
  .slider-container .rzslider .rz-pointer {
    top: -8px;
    width: 20px;
    height: 20px;
    background-color: #b8bfc1;
    border-radius: 50%; }
  .slider-container .rzslider .rz-pointer:after {
    top: 7px;
    left: 7px;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%; }
  .slider-container i {
    font-size: 14px;
    color: #758185; }
  .slider-container .label-left {
    position: absolute;
    top: 3px; }
  .slider-container .label-right {
    position: absolute;
    top: 3px;
    right: 0; }
  .slider-container .current-zoom {
    position: absolute;
    top: 3px;
    right: -30px; }

.tile-container {
  clear: both;
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
  float: left; }

.tile {
  display: inline-block;
  position: relative;
  text-align: center;
  margin: 15px;
  padding: 0;
  padding-bottom: 56px;
  border-radius: 0;
  transition: all .2s ease-in-out; }
  .tile:hover {
    transform: scale(1.03);
    z-index: 1; }
  .tile .desc {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding: 5px 10px;
    text-align: center; }
    .tile .desc .tags {
      display: none; }
  .tile .desc h1 {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 3px; }
  .tile .preview {
    cursor: pointer;
    width: 170px;
    height: 120px; }
    .tile .preview > div {
      position: relative;
      border: none;
      padding: 0;
      background-color: transparent;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      width: inherit;
      height: inherit;
      word-break: break-all; }
    .tile .preview .preview-no-image {
      flex-direction: column; }
      .tile .preview .preview-no-image a {
        word-break: break-all; }
      .tile .preview .preview-no-image .filesize {
        opacity: 0.5;
        margin-top: 5px; }
  .tile .tags {
    height: 32px;
    overflow: hidden; }
  .tile .tag {
    font-size: 10px;
    padding: 0px 4px;
    margin-top: 2px;
    margin-bottom: 3px; }

.list-container.row {
  margin-left: -5px;
  margin-right: -5px; }
  .list-container.row .file-element {
    padding-left: 5px;
    padding-right: 5px; }
    .list-container.row .file-element.tile {
      padding-top: 5px; }

.image-preview-popup {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s;
  opacity: 0; }
  .image-preview-popup.active {
    opacity: 1; }

.list-element {
  position: relative;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 0;
  transition: all .2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  background-color: #fff; }
  .list-element .list {
    display: flex;
    justify-content: space-around; }
  .list-element.selected {
    background-color: #def1ff; }
  .list-element .preview {
    position: relative;
    width: 100px;
    height: 100px; }
    .list-element .preview > div {
      width: inherit;
      height: inherit;
      border: none;
      padding: 0;
      background-color: transparent;
      transition: transform .2s ease-in-out;
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-direction: column; }
    .list-element .preview .preview-no-image {
      font-size: 24px; }
      .list-element .preview .preview-no-image i {
        opacity: 0.5; }
    .list-element .preview a {
      pointer-events: all;
      z-index: 6; }
    .list-element .preview.edit img {
      opacity: 0.5; }
  .list-element .preview:not(.edit) .preview-no-image,
  .list-element .preview:not(.edit) .preview-image {
    transition: transform .2s ease-in-out; }
    .list-element .preview:not(.edit) .preview-no-image:hover,
    .list-element .preview:not(.edit) .preview-image:hover {
      transform: scale(1.05); }
      .list-element .preview:not(.edit) .preview-no-image:hover img,
      .list-element .preview:not(.edit) .preview-image:hover img {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3); }
  .list-element .f-infos {
    margin-left: 30px;
    width: 35%; }
    .list-element .f-infos.last {
      margin-right: 30px;
      width: 25%; }
      .list-element .f-infos.last.edit {
        margin-right: 80px; }
  .list-element .f-context-menu {
    position: absolute;
    top: 15px;
    right: 15px; }
  .list-element .f-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .list-element .f-filename {
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .list-element .f-filename .filesize {
      margin-left: 19px; }
  .list-element .f-created {
    color: #758185; }
  .list-element .f-name {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px; }
  .list-element .f-filename {
    color: #758185; }
    .list-element .f-filename a {
      color: #758185; }
      .list-element .f-filename a:hover {
        color: #000; }

.tag {
  padding: 1px 6px;
  background-color: #e1e4e5;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  float: left; }

.tag-selection {
  clear: both;
  padding-top: 10px;
  margin-bottom: 10px; }
  .tag-selection .headline {
    position: relative;
    margin-bottom: 10px; }
    .tag-selection .headline:before {
      content: "";
      left: 0;
      right: 0;
      bottom: 5px;
      position: absolute;
      height: 1px;
      background-color: #ddd; }
    .tag-selection .headline span {
      background-color: #fff;
      padding: 3px;
      padding-left: 0;
      position: relative;
      color: #666; }

tags-input .tags {
  -webkit-appearance: none;
  border: 1px solid #ccc;
  box-shadow: none; }
  tags-input .tags .tag-item {
    font: inherit;
    line-height: 26px;
    border: none;
    background-color: #b8bfc1;
    background-image: none;
    color: black; }
    tags-input .tags .tag-item .remove-button {
      color: #585858; }
  tags-input .tags .input {
    height: 22px;
    font: inherit;
    font-size: 11px;
    width: auto; }

tags-input .host {
  margin-top: 0;
  margin-bottom: 0; }

.bulkedit tags-input .input {
  width: auto !important; }

.table.files {
  table-layout: fixed; }
  .table.files td {
    word-break: break-all; }

.table.files > tbody > tr > td:first-child {
  padding-left: 0;
  min-width: 100px; }

.datepicker .dropdown-menu {
  padding: 10px; }
  .datepicker .dropdown-menu .btn {
    border: none;
    margin: 2px; }
  .datepicker .dropdown-menu em {
    font-style: normal;
    color: #ccc; }

.dropdown-menu li > a.active:before {
  font-family: FontAwesome;
  content: "\f00c";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  margin-left: -15px; }

.dropzone {
  transition: all 0.3s ease-out;
  border-width: 1px;
  border-color: #101010;
  border-style: dashed;
  border-radius: 2px;
  height: 150px; }
  .dropzone.dz-drag-hover {
    border-style: dashed;
    box-shadow: inset 0 0 0 10px #66afe9; }
    .dropzone.dz-drag-hover .dz-message {
      opacity: 0.5; }

.dropzone-global-buttons {
  text-align: right; }

#total-progress {
  opacity: 0;
  transition: opacity 0.3s linear;
  margin-bottom: 7px;
  margin-top: 7px; }

.upload-area .global-container {
  padding-top: 10px; }

.upload-area {
  position: relative;
  margin-top: -214px;
  margin-bottom: 50px;
  left: 0;
  right: 0;
  transition: margin-top 0.5s; }
  .upload-area.open {
    margin-top: -30px; }
  .upload-area .upload-container {
    background-color: #eff0f1;
    border-bottom: 1px solid #dbe2e7;
    padding: 0 15px;
    padding-top: 15px; }
  .upload-area .btn-toggle-upload {
    position: absolute;
    cursor: pointer;
    bottom: -29px;
    right: 15px;
    height: 29px;
    padding: 6px 12px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: none;
    background-color: #b8bfc1; }
    .upload-area .btn-toggle-upload:hover {
      background-color: #abb2b5; }
  .upload-area .dropzone.dz-clickable button {
    cursor: pointer; }

.dropzone .dz-message {
  margin: 3em 0; }
  .dropzone .dz-message:before {
    font-family: FontAwesome;
    content: "\f063";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle; }

div.table .file-row div:last-child {
  text-align: right;
  padding-right: 0;
  white-space: nowrap; }

div.table .file-row div:first-child {
  padding-left: 0; }

div.table > div:nth-child(3) > div {
  border-top: none; }

.file-row div.form-inline .form-control {
  width: 100%; }

.col-md-12.filter .col-xs-12 {
  width: 50%; }

/*
// for form in filter
@media (min-width: 992px) {
  .form-inline .form-group {
    display: block;
    margin-bottom: 15px;
    vertical-align: middle;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn {
    width: 1%;
  }
}
*/
.color-tags-dropdown.disabled {
  opacity: 0.5; }

.color-tags-dropdown .dropdown-toggle {
  margin-right: 3px; }
  .color-tags-dropdown .dropdown-toggle .img-color-tag {
    float: left;
    margin-right: 4px; }

.color-tags-dropdown .dropdown-menu {
  min-width: auto;
  padding: 0;
  border-radius: 5px; }
  .color-tags-dropdown .dropdown-menu > li > a {
    margin: 10px;
    padding: 0;
    height: 15px; }
    .color-tags-dropdown .dropdown-menu > li > a:hover, .color-tags-dropdown .dropdown-menu > li > a:focus {
      background-color: transparent;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); }

.color-tags-dropdown.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.color-filter .dropdown-menu > li > a.cf.active:before {
  padding-right: 0; }

.btn-color-tag {
  color: #333;
  background-color: #fff;
  border-color: transparent; }

.img-color-tag {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid transparent; }
  .img-color-tag.color-none {
    border: 1px solid #777; }
    .img-color-tag.color-none:before, .img-color-tag.color-none:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 1px;
      background-color: #777;
      top: 6px;
      left: -2px; }
    .img-color-tag.color-none:before {
      transform: rotate(40deg); }
    .img-color-tag.color-none:after {
      transform: rotate(-40deg); }
  .img-color-tag.color-red {
    background-color: #e72c14; }
  .img-color-tag.color-orange {
    background-color: #ffa62e; }
  .img-color-tag.color-yellow {
    background-color: #f2ee0d; }
  .img-color-tag.color-green, .img-color-tag.color-green:hover {
    background-color: #11ed2a; }
  .img-color-tag.color-blue {
    background-color: #4892ed; }
  .img-color-tag.color-grey {
    background-color: #d5d8d4; }

.rating-container {
  display: inline-block; }
  .rating-container.disabled {
    opacity: 0.5; }
  .rating-container i {
    cursor: pointer;
    opacity: 0.3; }
    .rating-container i.active {
      opacity: 0.9; }
  .rating-container .fa-star-o {
    color: #999999; }

.tags-container {
  display: inline-block; }

.color-filter li {
  text-transform: capitalize; }

.textlink {
  margin-left: 20px;
  white-space: nowrap; }

.bulk-edit {
  margin-top: 15px;
  border-top: 1px solid #cbcdcd;
  padding-top: 26px; }
  .bulk-edit .btn-default {
    width: 110px; }
  .bulk-edit .color-tags-dropdown,
  .bulk-edit .rating-container {
    margin-top: 7px; }
  .bulk-edit .text-info {
    margin-right: 20px; }

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
.list-container[dnd-list] .dndDraggingSource {
  display: none; }

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
.list-container[dnd-list] .list.dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 100px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px; }

.list-container[dnd-list] .tile.dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 150px;
  width: 150px; }

/**
 * Show selected elements in green
 */
.list-container[dnd-list] .selected {
  background-color: #dff0d8;
  color: #3c763d; }

div.colorpicker {
  position: absolute;
  width: 70px;
  height: 78px;
  z-index: 1;
  filter: drop-shadow(0 0px 2px rgba(0, 0, 0, 0.5));
  transition: filter .1s ease-in-out; }
  div.colorpicker.active {
    z-index: 2;
    filter: drop-shadow(0 0px 8px rgba(0, 0, 0, 0.8)); }
  div.colorpicker .content {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    z-index: 2; }
  div.colorpicker .color-area {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 23px;
    left: 5px;
    background-color: #ccc; }
  div.colorpicker .color-value {
    position: absolute;
    bottom: 3px;
    left: 10px;
    right: 10px;
    text-align: center; }
  div.colorpicker .color-value-rgb {
    position: absolute;
    bottom: 23px;
    left: 0;
    top: 5px;
    right: 0;
    padding-top: 3px;
    background-color: rgba(255, 255, 255, 0.8); }
  div.colorpicker .arrow {
    position: absolute;
    display: block;
    height: 20px;
    left: -2px;
    top: -10px;
    width: 40px;
    overflow: hidden;
    z-index: 1; }
    div.colorpicker .arrow:after {
      position: absolute;
      background-color: #fff;
      content: "\00a0";
      display: block;
      height: 30px;
      width: 30px;
      left: 2px;
      top: 18px;
      transform: skew(0deg, 25deg); }
  div.colorpicker .color-delete {
    background-color: #fff; }
  div.colorpicker i.close {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    opacity: 1;
    padding: 2px 2px 2px 4px;
    color: #333; }
  div.colorpicker.locked i.close {
    display: none; }

div.rect, div.hotspot {
  position: absolute;
  z-index: 1; }
  div.rect .rect-fill, div.hotspot .rect-fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    div.rect .rect-fill:before, div.hotspot .rect-fill:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px dotted rgba(255, 255, 255, 0.8); }
    div.rect .rect-fill:after, div.hotspot .rect-fill:after {
      content: " ";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #ff8080; }
    div.rect .rect-fill:hover.rect-fill:before, div.hotspot .rect-fill:hover.rect-fill:before {
      content: none; }
  div.rect.active, div.hotspot.active {
    z-index: 2; }
    div.rect.active .rect-fill, div.hotspot.active .rect-fill {
      background-color: rgba(255, 128, 128, 0.4); }
      div.rect.active .rect-fill:before, div.hotspot.active .rect-fill:before {
        content: none; }

.ui-resizable-handle {
  background-color: #ff8080;
  position: absolute;
  font-size: 0.1px;
  -ms-touch-action: none;
  touch-action: none;
  border: 1px solid rgba(255, 255, 255, 0.8);
  display: none; }

div.active .ui-resizable-handle {
  display: block; }

div.active.locked .ui-resizable-handle {
  display: none; }

.ui-resizable-n,
.ui-resizable-e,
.ui-resizable-s,
.ui-resizable-w {
  background-color: rgba(255, 128, 128, 0.8); }

.ui-resizable-n {
  top: -10px;
  cursor: n-resize;
  height: 10px;
  width: 10px;
  left: 50%;
  margin-left: -5px; }

.ui-resizable-e {
  right: -10px;
  cursor: e-resize;
  width: 10px;
  top: 50%;
  height: 10px;
  margin-top: -5px; }

.ui-resizable-s {
  bottom: -10px;
  cursor: s-resize;
  height: 10px;
  width: 10px;
  left: 50%;
  margin-left: -5px; }

.ui-resizable-w {
  left: -10px;
  cursor: w-resize;
  width: 10px;
  top: 50%;
  height: 10px;
  margin-top: -5px; }

.ui-resizable-se {
  cursor: se-resize;
  width: 10px;
  height: 10px;
  right: -10px;
  bottom: -10px; }

.ui-resizable-sw {
  left: -10px;
  bottom: -10px;
  cursor: sw-resize;
  width: 10px;
  height: 10px; }

.ui-resizable-ne {
  right: -10px;
  top: -10px;
  cursor: ne-resize;
  width: 10px;
  height: 10px; }

.ui-resizable-nw {
  left: -10px;
  top: -10px;
  cursor: nw-resize;
  width: 10px;
  height: 10px; }

.element-info {
  position: absolute;
  top: -60px;
  left: 0;
  white-space: nowrap;
  background-color: #169eff;
  color: #fff;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 11px;
  display: none; }
  .element-info:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
    pointer-events: none;
    border: solid transparent;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #169eff;
    border-width: 8px;
    margin-left: -8px; }
  .element-info td {
    text-align: left;
    padding: 1px 2px; }

div.hotspot .rect-fill {
  background-color: rgba(128, 206, 255, 0.4);
  transition: opacity 0.5s; }
  div.hotspot .rect-fill:after {
    border: 1px solid #5fbbff; }
  div.hotspot .rect-fill:before {
    content: " ";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: none; }

div.hotspot.active {
  z-index: 2; }
  div.hotspot.active .rect-fill {
    background-color: rgba(128, 206, 255, 0.7); }
    div.hotspot.active .rect-fill:before {
      border: 1px dotted rgba(255, 255, 255, 0.8); }
    div.hotspot.active .rect-fill:hover {
      cursor: auto; }

div.hotspot.locked .rect-fill {
  opacity: 0.7; }
  div.hotspot.locked .rect-fill:hover {
    cursor: pointer;
    opacity: 0.8;
    background-color: rgba(128, 206, 255, 0.8); }

div.hotspot.locked:active .rect-fill {
  opacity: 0.3; }

div.hotspot.locked.active .rect-fill {
  opacity: 0.7; }

div.hotspot .ui-resizable-handle,
div.hotspot .ui-resizable-n,
div.hotspot .ui-resizable-e,
div.hotspot .ui-resizable-s,
div.hotspot .ui-resizable-w {
  background-color: rgba(128, 206, 255, 0.8); }

.hotspot-link-description {
  color: #cccccc; }

.element-container div.hotspot .rect-fill {
  opacity: 0; }

.element-container:hover div.hotspot .rect-fill {
  opacity: 0.5; }

.element-container div.hotspot:not(.locked) .rect-fill,
.element-container:hover div.hotspot:not(.locked) .rect-fill {
  opacity: 0.5; }

.element-container div.hotspot:not(.locked).active .rect-fill,
.element-container:hover div.hotspot:not(.locked).active .rect-fill {
  border: 1px solid #39A8FF;
  opacity: 0.7; }
  .element-container div.hotspot:not(.locked).active .rect-fill:before,
  .element-container:hover div.hotspot:not(.locked).active .rect-fill:before {
    border: 1px dotted #ffffff; }
  .element-container div.hotspot:not(.locked).active .rect-fill:hover,
  .element-container:hover div.hotspot:not(.locked).active .rect-fill:hover {
    cursor: auto; }

div.ruler {
  position: absolute;
  z-index: 1;
  cursor: pointer; }
  div.ruler .value {
    position: absolute;
    background-color: #666;
    padding: 0 5px 0 5px;
    color: white;
    pointer-events: none;
    /* dont listen to mouse events and forward them */
    border-radius: 3px;
    min-width: 40px;
    text-align: center; }
  div.ruler .line {
    position: absolute;
    background-color: #ff7400; }
    div.ruler .line.vertical {
      width: 1px;
      left: 5px;
      top: 0;
      bottom: 0; }
      div.ruler .line.vertical:before {
        position: absolute;
        content: "";
        top: 0;
        left: -4px;
        right: -4px;
        height: 1px;
        background-color: #ff7400; }
      div.ruler .line.vertical:after {
        position: absolute;
        content: "";
        bottom: 0;
        left: -4px;
        right: -4px;
        height: 1px;
        background-color: #ff7400; }
    div.ruler .line.horizontal {
      height: 1px;
      top: 5px;
      left: 0;
      right: 0; }
      div.ruler .line.horizontal:before {
        position: absolute;
        content: "";
        left: 0;
        top: -4px;
        bottom: -4px;
        width: 1px;
        background-color: #ff7400; }
      div.ruler .line.horizontal:after {
        position: absolute;
        content: "";
        right: 0;
        top: -4px;
        bottom: -4px;
        width: 1px;
        background-color: #ff7400; }
  div.ruler.active {
    z-index: 2; }
    div.ruler.active .value {
      background-color: black; }
    div.ruler.active .line {
      background-color: #cd000d; }
      div.ruler.active .line.vertical:before {
        background-color: #cd000d; }
      div.ruler.active .line.vertical:after {
        background-color: #cd000d; }
      div.ruler.active .line.horizontal:before {
        background-color: #cd000d; }
      div.ruler.active .line.horizontal:after {
        background-color: #cd000d; }
  div.ruler.active .ui-resizable-handle {
    display: block; }
  div.ruler.active.locked .ui-resizable-handle {
    display: none; }
  div.ruler.locked .ui-resizable-handle {
    display: none; }
  div.ruler .ui-resizable-handle {
    background-color: transparent;
    position: absolute;
    font-size: 0.1px;
    display: block;
    -ms-touch-action: none;
    touch-action: none;
    border: none; }
  div.ruler .ui-resizable-n {
    top: -11px;
    cursor: n-resize;
    height: 10px;
    width: 10px;
    left: 0;
    margin-left: 0; }
  div.ruler .ui-resizable-e {
    right: -11px;
    cursor: e-resize;
    width: 10px;
    top: 0;
    height: 10px;
    margin-top: 0; }
  div.ruler .ui-resizable-s {
    bottom: -11px;
    cursor: s-resize;
    height: 10px;
    width: 10px;
    left: 0;
    margin-left: 0; }
  div.ruler .ui-resizable-w {
    left: -11px;
    cursor: w-resize;
    width: 10px;
    top: 0;
    height: 10px;
    margin-top: 0; }

ul.rulers {
  clear: both;
  list-style-type: none;
  margin: 80px 0 0 0;
  padding: 5px 0 5px 0;
  border-top: 1px solid #686868;
  border-bottom: 1px solid #686868; }
  ul.rulers li i {
    float: right;
    margin-top: 2px; }
  ul.rulers li .index {
    width: 12px;
    display: inline-block; }
  ul.rulers.locked li i {
    display: none; }
  ul.rulers li {
    padding: 5px;
    margin-left: -5px;
    margin-right: -5px;
    cursor: pointer; }
  ul.rulers li.active {
    color: #fff; }
  ul.rulers li:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px; }

.image .note {
  position: absolute;
  z-index: 1;
  filter: drop-shadow(0 0px 2px rgba(0, 0, 0, 0.5));
  transition: filter .1s ease-in-out; }
  .image .note.active {
    filter: drop-shadow(0 0px 8px rgba(0, 0, 0, 0.8));
    z-index: 2; }
  .image .note .content {
    background-color: #ffffff;
    border-radius: 3px;
    width: 200px;
    margin-top: 10px; }
    .image .note .content .header {
      height: 20px;
      background-color: #ececec;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      cursor: move;
      text-align: right;
      padding-top: 2px; }
      .image .note .content .header > i, .image .note .content .header > a {
        font-size: 12px;
        line-height: 1;
        text-shadow: none;
        margin-right: 4px; }
        .image .note .content .header > i:hover, .image .note .content .header > a:hover {
          color: #000;
          cursor: pointer;
          opacity: 1; }
    .image .note .content .body {
      padding: 5px; }
  .image .note .arrow {
    position: absolute;
    display: block;
    height: 22px;
    left: -2px;
    top: -10px;
    width: 40px;
    overflow: hidden; }
    .image .note .arrow:after {
      position: absolute;
      background-color: #ececec;
      content: "\00a0";
      display: block;
      height: 30px;
      width: 30px;
      left: 2px;
      top: 18px;
      transform: skew(0deg, 25deg); }
  .image .note input.form-control {
    font-size: 12px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 100%;
    padding: 3px 6px;
    height: 26px;
    border-radius: 2px; }
  .image .note .form-control {
    color: #C6CBCD;
    background-color: transparent;
    border: 1px solid #ccc; }
  .image .note ul.comments {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left; }
    .image .note ul.comments span.user {
      color: #66c2e0; }
    .image .note ul.comments li {
      padding: 6px 2px;
      margin: 2px 0; }
      .image .note ul.comments li:first-child {
        border-top: none; }
      .image .note ul.comments li .comment {
        white-space: pre-wrap; }
  .image .note textarea {
    padding: 3px 6px; }
  .image .note .editable-buttons {
    white-space: normal;
    margin-top: 5px;
    margin-left: -5px;
    display: flex;
    justify-content: space-between; }
    .image .note .editable-buttons.single {
      margin-left: 0; }
      .image .note .editable-buttons.single button {
        margin-left: 0; }
    .image .note .editable-buttons button {
      padding: 2px 12px;
      margin-left: 5px;
      flex-grow: 2; }
  .image .note .comment-hints {
    text-align: right;
    color: #9c9c9c; }
  .image .note .layout-options {
    margin-right: 5px;
    vertical-align: initial; }
    .image .note .layout-options .dropdown-toggle {
      padding: 0 5px; }
  .image .note.layout-1 .arrow {
    right: -2px;
    left: auto; }
    .image .note.layout-1 .arrow:after {
      left: 8px;
      transform: skew(0deg, -25deg); }
  .image .note.layout-99 .arrow {
    display: none; }

.image div.note.locked div.content div.header {
  cursor: auto; }
  .image div.note.locked div.content div.header .layout-options,
  .image div.note.locked div.content div.header i.close-note {
    display: none; }

.loupe-container {
  position: relative;
  width: 252px;
  height: 204px;
  margin: 9px auto;
  overflow: hidden;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  flex-shrink: 0; }
  .loupe-container .loupe-info {
    position: absolute;
    width: inherit;
    height: inherit;
    left: 0;
    top: 0;
    text-align: center;
    padding-top: 38%;
    background-color: #8a8a8a;
    z-index: 2;
    color: #000; }
  .loupe-container .loupe-pixel-indicator {
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    width: 6px;
    height: 6px;
    border: 1px solid red;
    z-index: 1; }
  .loupe-container .elements {
    pointer-events: none; }

#canvas-loupe-pixelgrid {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  /* dont listen to mouse events and forward them */ }

.validation-error {
  color: red;
  margin-top: -8px;
  margin-bottom: 10px; }

input.ng-dirty.ng-invalid {
  color: red;
  border-color: red; }

.btn:active, .btn.active {
  background-image: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.125); }

.btn-default:hover {
  background-color: #C6CBCD;
  border-color: #9da6a9; }

.btn-default:focus,
.btn-default.focus {
  background-color: transparent;
  border-color: #cccccc; }

.btn-default:active,
.btn-default.active,
.open > .btn-default.dropdown-toggle {
  background-color: #b8bfc1;
  border-color: #9da6a9; }

.btn-default:active:hover,
.btn-default:active:focus,
.btn-default:active.focus,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus,
.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus {
  background-color: #abb2b5;
  border-color: #9da6a9; }

.well {
  box-shadow: none; }

.table.vertical-align-middle td {
  vertical-align: middle;
  height: 48px; }

.table-hover tbody tr:hover > td {
  cursor: pointer; }

.table > thead > tr > th {
  border-bottom: 1px solid #333;
  font-weight: normal; }

td.text-right {
  white-space: nowrap; }

.editable-buttons .btn-default {
  background-color: #fff !important;
  border-color: #ccc !important; }
  .editable-buttons .btn-default:hover {
    background-color: #F0F3F5 !important;
    border-color: #c2ced6 !important; }

.dropdown-menu > li.headline {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #7b7b7b;
  white-space: nowrap; }

.image .hint {
  position: absolute;
  z-index: 1;
  filter: drop-shadow(0 0px 2px rgba(0, 0, 0, 0.5));
  transition: filter .1s ease-in-out; }
  .image .hint .dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    cursor: pointer; }
    .image .hint .dot:after {
      content: "";
      position: absolute;
      margin: 0 auto;
      border-radius: 100px;
      left: -10px;
      top: -10px;
      background-color: transparent;
      opacity: 1;
      width: 30px;
      height: 30px;
      border: 5px solid red;
      /* Giving Animation Function */
      -webkit-animation: pulseRay 2s linear infinite;
      -moz-animation: pulseRay 2s linear infinite;
      border-image: initial; }
    .image .hint .dot.no-pulse:after {
      -webkit-animation: none;
      -moz-animation: none;
      display: none; }
  .image .hint.active {
    filter: drop-shadow(0 0px 8px rgba(0, 0, 0, 0.8));
    z-index: 2; }
  .image .hint .content {
    background-color: #ffffff;
    border-radius: 3px;
    width: 200px;
    margin-top: 5px;
    margin-left: 5px; }
    .image .hint .content .body {
      padding: 0 5px 5px 5px;
      text-align: left; }
    .image .hint .content .header {
      height: 20px;
      cursor: move;
      text-align: right;
      padding-top: 2px; }
      .image .hint .content .header > i, .image .hint .content .header > a {
        font-size: 12px;
        line-height: 1;
        text-shadow: none;
        margin-right: 4px; }
        .image .hint .content .header > i:hover, .image .hint .content .header > a:hover {
          color: #000;
          cursor: pointer;
          opacity: 1; }
  .image .hint input.form-control {
    font-size: 12px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 100%;
    padding: 3px 6px;
    height: 26px;
    border-radius: 2px; }
  .image .hint .form-control {
    color: #C6CBCD;
    background-color: transparent;
    border: 1px solid #ccc; }
  .image .hint .text {
    margin: 0;
    padding: 0;
    text-align: left;
    white-space: pre-wrap; }
  .image .hint textarea {
    padding: 3px 6px; }
  .image .hint .editable-buttons {
    white-space: normal;
    margin-top: 5px;
    margin-left: -5px;
    display: flex;
    justify-content: space-between; }
    .image .hint .editable-buttons.single {
      margin-left: 0; }
      .image .hint .editable-buttons.single button {
        margin-left: 0; }
    .image .hint .editable-buttons button {
      padding: 2px 12px;
      margin-left: 5px;
      flex-grow: 2; }

.image .hint.locked .content .header {
  cursor: auto; }
  .image .hint.locked .content .header .layout-options,
  .image .hint.locked .content .header i.delete-hint {
    display: none; }

@-webkit-keyframes pulseRay {
  0% {
    -webkit-transform: scale(0);
    opacity: 0; }
  8% {
    -webkit-transform: scale(0);
    opacity: 0; }
  15% {
    -webkit-transform: scale(0.1);
    opacity: 1; }
  30% {
    -webkit-transform: scale(0.5);
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: scale(1); } }

@-moz-keyframes pulseRay {
  0% {
    -moz-transform: scale(0);
    opacity: 0; }
  8% {
    -moz-transform: scale(0);
    opacity: 0; }
  15% {
    -moz-transform: scale(0.1);
    opacity: 1; }
  30% {
    -moz-transform: scale(0.5);
    opacity: 1; }
  100% {
    opacity: 0;
    -moz-transform: scale(1); } }

div.measure {
  position: absolute;
  background-color: rgba(6, 255, 3, 0.6);
  z-index: 1; }
  div.measure .value {
    position: absolute;
    background-color: #666;
    padding: 0 5px 0 5px;
    color: white;
    pointer-events: none;
    /* dont listen to mouse events and forward them */
    border-radius: 3px;
    min-width: 40px;
    text-align: center; }
  div.measure .measure-info {
    background-color: #000;
    color: #fff;
    position: absolute;
    z-index: 10;
    border-radius: 3px;
    padding: 2px 4px;
    pointer-events: none; }

.image .panel.left .revisions-container,
.image .panel.left .details-container {
  display: flex;
  flex-direction: column;
  flex-grow: 2; }

.image .panel.left .comments-container {
  display: flex;
  flex-direction: column;
  flex-grow: 2; }

.image .panel.left file-comments,
.image .panel.left revisions {
  display: flex;
  flex-direction: column;
  flex-grow: 2; }

.image .panel.left ul.text-container {
  list-style: none;
  padding: 0;
  flex-grow: 2;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f8f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column; }
  .image .panel.left ul.text-container li {
    padding: 6px 6px;
    margin: 2px 0; }
    .image .panel.left ul.text-container li .user {
      font-weight: bold; }
    .image .panel.left ul.text-container li.empty {
      color: #888888;
      margin: auto;
      text-align: center; }
    .image .panel.left ul.text-container li .comment,
    .image .panel.left ul.text-container li .revision {
      white-space: pre-wrap;
      color: #3578b1; }
    .image .panel.left ul.text-container li textarea {
      color: #333;
      border: 1px solid #ccc; }
    .image .panel.left ul.text-container li .header {
      color: #000;
      margin-bottom: 3px;
      padding-bottom: 5px; }
      .image .panel.left ul.text-container li .header .badge.index {
        vertical-align: inherit;
        border-radius: 2px;
        background-color: #66c2e0; }
      .image .panel.left ul.text-container li .header .created {
        color: #8a8d8e; }
    .image .panel.left ul.text-container li.comment-container:first-child {
      border-top: none; }

.image .panel.left .add-entry-form {
  margin: 10px; }
  .image .panel.left .add-entry-form textarea {
    margin-top: 5px; }

.image .panel.left .simple-output-input {
  display: flex;
  flex-direction: column;
  background-color: #3c3c3c;
  flex-grow: 2; }
  .image .panel.left .simple-output-input .editable-buttons {
    white-space: normal;
    margin-top: 5px;
    margin-left: -5px;
    display: flex;
    justify-content: space-between; }
    .image .panel.left .simple-output-input .editable-buttons.single {
      margin-left: 0; }
      .image .panel.left .simple-output-input .editable-buttons.single button {
        margin-left: 0; }
    .image .panel.left .simple-output-input .editable-buttons button {
      padding: 2px 12px;
      margin-left: 5px;
      flex-grow: 2; }
  .image .panel.left .simple-output-input .add-entry-form .btn-default {
    color: #C6CBCD;
    background-color: #676767;
    border-color: #676767;
    height: 22px;
    padding: 2px 4px;
    margin-top: 5px;
    width: 100%; }

.file .add-entry-form .btn-default {
  float: right; }

whiteboard {
  position: relative;
  display: block;
  z-index: 10;
  pointer-events: none; }
  whiteboard.active {
    pointer-events: all; }

#whiteboard {
  display: block; }

.wcursor {
  position: absolute;
  top: 0;
  left: 0;
  display: none; }

.whiteboard-toolbar {
  position: absolute;
  left: 280px;
  width: 50px;
  top: 50px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
  background-color: #f5f8f9;
  color: #333333;
  padding: 5px;
  margin: 0;
  word-wrap: break-word;
  z-index: 99; }
  .whiteboard-toolbar .form-group:last-child {
    margin-bottom: 0;
    margin-top: 10px; }
  .whiteboard-toolbar .form-group:first-child {
    margin-bottom: 0; }
  .whiteboard-toolbar.fullscreen {
    top: 0; }
  .whiteboard-toolbar button {
    width: 100%;
    margin-bottom: 2px;
    margin-top: 2px;
    padding: 6px 6px;
    height: 34px; }
  .whiteboard-toolbar .tools-label {
    position: relative;
    margin-bottom: 10px;
    color: #909AA0;
    overflow: hidden; }
    .whiteboard-toolbar .tools-label:after {
      position: absolute;
      top: 10px;
      overflow: hidden;
      width: 100%;
      height: 1px;
      content: '\a0';
      background-color: #686868;
      margin-left: 5px; }
  .whiteboard-toolbar hr {
    margin: 0;
    border-color: #cccccc; }
  .whiteboard-toolbar .btn-tools {
    color: #333333;
    background-color: transparent; }
    .whiteboard-toolbar .btn-tools .fa {
      font-size: 18px; }
    .whiteboard-toolbar .btn-tools span {
      font-size: 18px; }
    .whiteboard-toolbar .btn-tools.locking {
      border: none;
      margin: 0;
      height: auto; }
      .whiteboard-toolbar .btn-tools.locking.locked {
        background-color: #cccccc; }
        .whiteboard-toolbar .btn-tools.locking.locked.active {
          background-color: #32ab44;
          color: #f4f4f4;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.15); }
      .whiteboard-toolbar .btn-tools.locking.unlocked {
        background-color: #cccccc; }
        .whiteboard-toolbar .btn-tools.locking.unlocked.active {
          background-color: #32ab44;
          color: #f4f4f4;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.15); }
    .whiteboard-toolbar .btn-tools:hover {
      background-color: #efefef; }
    .whiteboard-toolbar .btn-tools.active, .whiteboard-toolbar .btn-tools:active {
      background-color: rgba(0, 0, 0, 0.3);
      color: #959DA1; }
    .whiteboard-toolbar .btn-tools.disabled {
      opacity: 0.4; }
      .whiteboard-toolbar .btn-tools.disabled.active {
        background-color: transparent;
        box-shadow: none;
        color: #c0cbd1; }
        .whiteboard-toolbar .btn-tools.disabled.active:hover {
          background-color: #efefef; }
    .whiteboard-toolbar .btn-tools.parallel {
      width: 18px;
      display: inline-block;
      padding: 0; }
    .whiteboard-toolbar .btn-tools .icon-tip-size {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      display: block;
      background-color: black;
      margin: auto; }
      .whiteboard-toolbar .btn-tools .icon-tip-size.icon-tip-size-1 {
        width: 5px;
        height: 5px; }
      .whiteboard-toolbar .btn-tools .icon-tip-size.icon-tip-size-2 {
        width: 10px;
        height: 10px; }
      .whiteboard-toolbar .btn-tools .icon-tip-size.icon-tip-size-3 {
        width: 15px;
        height: 15px; }
    .whiteboard-toolbar .btn-tools .icon-color {
      border-radius: 2px;
      width: 18px;
      height: 18px;
      display: block;
      background-color: black;
      margin: auto; }

.toggle-lock {
  display: block; }
  .toggle-lock.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 2px; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0); }

::-webkit-scrollbar-thumb {
  background-color: rgba(209, 209, 209, 0.5); }

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; }

::-webkit-input-placeholder {
  font-style: normal;
  color: #909AA0; }

::-moz-placeholder {
  font-style: normal;
  color: #909AA0; }

/* firefox 19+ */
:-ms-input-placeholder {
  font-style: normal;
  color: #909AA0; }

/* ie */
input:-moz-placeholder {
  font-style: normal;
  color: #909AA0; }

:focus {
  outline: none !important; }

body {
  font-family: 'Noto Sans', sans-serif;
  background-color: #e4e4e4; }

h1 {
  font-size: 24px; }

a {
  color: #000000; }

a:focus, a:hover {
  color: #666;
  text-decoration: none; }

i.list-delete {
  float: right; }

body > div > .container-fluid {
  padding-left: 40px;
  padding-right: 40px; }

@media (max-width: 992px) {
  body > div > .container-fluid {
    padding-left: 10px;
    padding-right: 10px; } }

.page-content {
  padding-top: 30px;
  margin-top: 40px; }

div.login.row {
  margin-top: 30px; }

.editable-click, a.editable-click {
  color: inherit;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  border-bottom: none; }

.editable-click:hover, a.editable-click:hover {
  text-decoration: none;
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.5); }

.form-group.editable-controls {
  margin-bottom: 0;
  width: inherit; }

.form-control {
  font-size: 11px;
  height: 31px; }

.result {
  float: right; }

.tag-selection button {
  margin-right: 5px;
  margin-bottom: 5px; }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
  outline-offset: 0; }

.btn-tag.active, .btn-tag:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.125);
  background-color: #abb2b5; }

.btn-tag {
  padding: 2px 5px;
  background-color: #e1e4e5; }

.btn.edit {
  opacity: 0.3;
  padding: 3px 3px 3px 0;
  background-color: transparent; }
  .btn.edit:hover {
    opacity: 1;
    background-color: transparent; }

div.table {
  display: table; }
  div.table .file-row {
    display: table-row; }
    div.table .file-row > div {
      display: table-cell;
      vertical-align: top;
      border-top: 1px solid #ddd;
      padding: 8px; }
    div.table .file-row.replace > div {
      border-top: none; }

.non-editable {
  color: #999999; }

.list-group-item {
  border: none;
  border-top: 1px solid #ddd; }
  .list-group-item:first-child {
    border: none; }

.select-project {
  margin-top: 30px; }

tags-input .autocomplete .suggestion-item {
  font: inherit; }

tags-input .autocomplete .suggestion-item em {
  font: inherit;
  font-weight: bold; }

.chardinjs-show-element {
  z-index: inherit;
  opacity: 1; }

.chardinjs-show-element a {
  color: #4dbaf0; }

.chardinjs-overlay {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  overflow: hidden; }

.chardinjs-helper-layer.chardinjs-left,
.chardinjs-helper-layer.chardinjs-right,
.chardinjs-helper-layer.chardinjs-bottom,
.chardinjs-helper-layer.chardinjs-top {
  border: none;
  padding: 0;
  margin: 0; }

.chardinjs-tooltip {
  background-color: yellow;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  width: 180px;
  filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.4)); }

.chardinjs-tooltip.chardinjs-left {
  margin-left: -60px;
  padding-right: 10px; }

.chardinjs-tooltip.chardinjs-right {
  margin-right: -60px;
  padding-left: 10px; }

.chardinjs-tooltip.chardinjs-bottom {
  margin-bottom: -20px;
  padding-top: 4px; }

.chardinjs-tooltip.chardinjs-top {
  margin-top: -20px;
  padding-bottom: 10px; }

.chardinjs-tooltip.chardinjs-right:before,
.chardinjs-tooltip.chardinjs-left:after,
.chardinjs-tooltip.chardinjs-bottom:before,
.chardinjs-tooltip.chardinjs-top:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  display: inline-block;
  background-color: transparent; }

.chardinjs-tooltip.chardinjs-right:before {
  border-width: 6px 8px 6px 0;
  border-color: transparent yellow transparent transparent;
  margin-top: -6px; }

.chardinjs-tooltip.chardinjs-bottom:before {
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent yellow transparent;
  margin-left: -8px; }

.chardinjs-tooltip.chardinjs-left:after {
  border-width: 6px 0 6px 8px;
  border-color: transparent transparent transparent yellow;
  margin-top: -6px; }

.chardinjs-tooltip.chardinjs-top:after {
  border-width: 6px 6px 0 8px;
  border-color: yellow transparent transparent transparent;
  margin-top: -6px; }

.chardinjs-tooltip.chardinjs-right:before,
.chardinjs-tooltip.chardinjs-left:after {
  width: 70px;
  top: 50%; }

.chardinjs-tooltip.chardinjs-bottom:before, .chardinjs-tooltip.chardinjs-top:after {
  width: 1px;
  height: 56px;
  left: 50%; }

.chardinjs-tooltip.chardinjs-bottom:before {
  top: -56px; }

.chardinjs-tooltip.chardinjs-top:after {
  bottom: -56px; }

.chardinjs-tooltip.chardinjs-right:before {
  left: -70px; }

.chardinjs-tooltip.chardinjs-left:after {
  right: -70px; }

.chardinjs-tooltip h1 {
  margin-top: 3px;
  font-size: 18px; }

.chardinjs-tooltip.help-centered {
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: none;
  white-space: nowrap; }
  .chardinjs-tooltip.help-centered td {
    padding: 5px 20px 4px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .chardinjs-tooltip.help-centered tr:last-child td {
    border-bottom: none; }
  .chardinjs-tooltip.help-centered td:last-child {
    text-align: right;
    padding-right: 0; }

.panel {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  border: none;
  border-radius: 0; }
  .panel .panel-heading {
    background-color: #F1F2F2; }
    .panel .panel-heading h3 {
      display: inline-block; }
  .panel.secondary .panel-heading {
    background-color: #CBCDCD; }
  .panel .panel-body .table {
    margin-bottom: 0px; }

label {
  font-weight: normal; }

.col-centered {
  float: none;
  margin: 0 auto; }

.csspinner:before {
  position: absolute;
  z-index: 2;
  content: "";
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  animation: fa-spin 0.75s infinite linear;
  border-right: 4px solid #666;
  border-top: 4px solid #B8B8B8;
  border-left: 4px solid #B8B8B8;
  border-bottom: 4px solid #B8B8B8; }

.csspinner:after {
  position: absolute;
  z-index: 1;
  content: "";
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 100%;
  background-color: rgba(228, 228, 228, 0.8); }

button.fixed-size {
  min-width: 100px; }

img.icon {
  width: 18px; }

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto; }

.form-horizontal.no-gutter .form-group,
.row.no-gutter {
  margin-left: 0;
  margin-right: 0; }

.form-horizontal.no-gutter .form-group [class*='col-']:not(:first-child),
.form-horizontal.no-gutter .form-group [class*='col-']:not(:last-child),
.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) {
  padding-right: 0;
  padding-left: 0; }

.filter .dropdown .btn {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: 24px; }
  .filter .dropdown .btn .caret {
    position: absolute;
    right: 5px;
    margin: 6px; }

/* (cs)spinner standard rotation animation used for duo, double-up etc. */
@-webkit-keyframes standard {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes standard {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

[class^="icon-"], [class*=" icon-"] {
  vertical-align: middle; }

.icon-stack {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 2em;
  vertical-align: middle; }

.icon-stack-1x, .icon-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.icon-rectangle_fill:before,
.icon-pointer_fill:before,
.icon-color_fill:before {
  opacity: 0.5; }
